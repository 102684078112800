// #region Imports

import React from 'react';

// Material-UI
import AppBar from '@mui/material/AppBar';
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridSelectionModel,
  GridValueGetterParams
} from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';

// Styles
import { useTheme } from '@mui/material/styles';


// Components
import EmailQueueSummary from './EmailQueueSummary';

import {
  LodgeUseMemberRequest,
  LodgeUseStatusChange
} from '../../models';

import {
  EMPTY_STRING,
  LodgeUseAction,
} from '../../global';

// UTC to local date formatting hooks
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// #endregion

// #region Interfaces

interface GuestsProps {
  refreshEmailQueue: boolean;
  handleStatusChange: (changes: LodgeUseStatusChange) => void;
  requests: Array<LodgeUseMemberRequest>;
}

// #endregion

const Guests: React.FC<GuestsProps> = (props) => {

  const { dateFormatted } = useDateFormatting();

  const [action, setAction] = React.useState<LodgeUseAction>(LodgeUseAction.Confirm);
  const [guestsSelected, setGuestsSelected] = React.useState<Array<number>>([]);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      height: '100%',
      padding: theme.spacing(2),
    },
    appBar: {
      margin: theme.spacing(0, 0, 1, 0)
    },
    gridContainer: {
      flexGrow: 1,
      height: '100%'
    },
    noRows: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    toolbarItem: {
      borderRight: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(0, 0, 0, 1),
      padding: theme.spacing(0, 1, 0, 1),
    },
  };

  // #endregion

  // #region Methods

  /*
* Grid column definitions
*/
  const columns = (): Array<GridColDef> => {

    return [
      {
        field: 'referenceNumber',
        headerName: '#',
        width: 85,
        type: 'string',
        align: 'left',
      },
      {
        field: 'firstName',
        headerName: 'Name',
        width: 200,
        type: 'string',
        align: 'left',
        valueGetter: renderName
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 100,
        type: 'string',
        align: 'left',
      },
      {
        field: 'rank',
        headerName: 'Rank',
        width: 75,
        type: 'number',
        align: 'left',
      },
      {
        field: 'guestOf',
        headerName: 'Guest Of',
        width: 150,
        type: 'string',
        align: 'left'
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 100,
        type: 'string',
        align: 'left'
      },
      {
        field: 'priorityGuest',
        headerName: 'Priority Guest',
        width: 110,
        type: 'string',
        align: 'left',
        valueGetter: renderPriorityGuest
      },
      {
        field: 'arrivalDate',
        headerName: 'Dates',
        width: 200,
        type: 'string',
        align: 'left',
        valueGetter: renderDates
      }
    ];

  }

  /*
* Overlay displayed when no data
*/
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No guest requests to display</div>
      </GridOverlay>
    );
  }

  /*
   * Render guest dates
   */
  const renderDates = (params: GridValueGetterParams) => {
    return `${dateFormatted(new Date(params.row.arrivalDate))}-${dateFormatted(new Date(params.row.departureDate))}`;
  }

  /*
   * Render guest name
   */
  const renderName = (params: GridValueGetterParams) => {
    return `${params.row.lastName}, ${params.row.firstName} (${params.row.gender}) ${params.row.priorityGuest ? '*' : EMPTY_STRING}`;
  }

  /*
   * Render priority guest
   */
  const renderPriorityGuest = (params: GridValueGetterParams) => {
    return params.row.priorityGuest ? 'Yes' : 'No';
  }

  // #endregion

  // #region Event handlers

  /*
   * Handles toolbar action
   */
  const handleToolbarAction = (event: React.MouseEvent<HTMLElement>, a: LodgeUseAction) => {
    if (a !== null) {
      setAction(a);
    }
  };

  /*
   * Handle selected guest requests
   */
  const handleCheckboxSelected = (ids: Array<number>) => {
    setGuestsSelected(ids);
  }

  /*
   * Handles toolbar action status change
   */
  const handleStatusChange = () => {
    // Process selected guests
    const statusChange = new LodgeUseStatusChange();
    statusChange.guestSignupIDs = guestsSelected;
    statusChange.signupStatusID = action;
    props.handleStatusChange(statusChange);

    // Clear selections
    setGuestsSelected([]);
    setAction(LodgeUseAction.Confirm)
  };

  // #endregion

  return (
    <div style={styles.root}>
      <AppBar position="static" color="inherit" sx={styles.appBar}>
        <Toolbar>
          <ToggleButtonGroup
            disabled={props.requests.length === 0}
            value={action}
            exclusive
            onChange={handleToolbarAction}
          >
            <ToggleButton value={LodgeUseAction.Pending}>
              <Tooltip title="Pending">
                <InfoIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Denied}>
              <Tooltip title="Deny">
                <BlockIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Waitlist}>
              <Tooltip title="Waitlist">
                <PanToolIcon />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={LodgeUseAction.Confirm}>
              <Tooltip title="Confirm">
                <CheckCircleIcon />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title="Approve the status change for selected individuals">
            <span>
              <IconButton
                disabled={(guestsSelected.length === 0)}
                onClick={handleStatusChange}
                size="large"
                sx={styles.toolbarItem}
              >
                <PublishedWithChangesIcon />
              </IconButton>
            </span>
          </Tooltip>
          <EmailQueueSummary refresh={props.refreshEmailQueue} />
        </Toolbar>
      </AppBar>
      <Box sx={styles.gridContainer}>
        <DataGrid
          checkboxSelection={true}
          columns={columns()}
          components={{
            NoRowsOverlay: noRowsOverlay
          }}
          density="compact"
          disableColumnSelector={true}
          disableSelectionOnClick={true}
          getRowId={(row) => row.id}
          hideFooter={true}
          rows={props.requests}
          onSelectionModelChange={(m: GridSelectionModel) => handleCheckboxSelected(m as Array<number>)}
          selectionModel={guestsSelected}
        />
      </Box>
    </div >);

}

export default Guests;