// #region imports

import React from 'react';

import {
  addDays,
  areIntervalsOverlapping,
  format,
  isAfter,
  isBefore,
  isPast,
  isValid as isDateValid,
  subDays
} from 'date-fns';

// Material-UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  HTTPHeaders,
  PriorityPeriod,
  SignupResultStatus,
  TransferablePassSignup
} from '../../interfaces';

import {
  HouseholdRequestStatus,
  Member,
  MemberSignupInputForm,
  SignupNew,
} from '../../models';

import {
  API_URL,
  EMPTY_STRING,
  NULL_DATE,
  RequestMode,
  SignupExceptionEnum
} from '../../global';

import NonWeekendHolidayWarningDialog from './NonWeekendHolidayWarningDialog';
import PostDeadlineWarningDialog from './PostDeadlineWarningDialog';
import PriorityPeriodList from '../../shared/PriorityPeriodList';
import RequestFormHelpDialog from './RequestFormHelpDialog';
import RequestModeOptions from './RequestModeOptions';
import TransferablePasses from '../TransferablePasses';

// Redux
import { useSelector } from "react-redux";

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// Date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

// Signup date criteria
import { useSignupDates } from '../../hooks/UseSignupDates';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// #endregion

const RequestForm: React.FC = () => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();
  const { enqueueSnackbar } = useSnackbar();
  const { dateFormatted } = useDateFormatting();
  const { calcMaxArrival, calcMaxDeparture, calcMinArrival, calcMinDeparture, getRequestYearEndDate } = useSignupDates();

  const [arrivalDate, setArrivalDate] = React.useState<string>(EMPTY_STRING);
  const [departureDate, setDepartureDate] = React.useState<string>(EMPTY_STRING);
  const [formDisabled, setFormDisabled] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState<boolean>(false);
  const [helpDialogOpen, setHelpDialogOpen] = React.useState<boolean>(false);
  const [members, setMembers] = React.useState<Array<Member>>(new Array<Member>());
  const [maxArrival, setMaxArrival] = React.useState<string>(EMPTY_STRING);
  const [maxDeparture, setMaxDeparture] = React.useState<string>(EMPTY_STRING);
  const [minArrival, setMinArrival] = React.useState<string>(EMPTY_STRING);
  const [minDeparture, setMinDeparture] = React.useState<string>(EMPTY_STRING);
  const [nonWeekendHolidayWarningDialogOpen, setNonWeekendHolidayWarningDialogOpen] = React.useState<boolean>(false);
  const [notes, setNotes] = React.useState<string>(EMPTY_STRING);
  const [postDeadlineyWarningDialogOpen, setpostDeadlineWarningDialogOpen] = React.useState<boolean>(false);
  const [priorityPeriods, setPriorityPeriods] = React.useState<Array<PriorityPeriod>>([]);
  const [priorityPeriodReset, setPriorityPeriodReset] = React.useState<boolean>(false);
  const [requestMode, setRequestMode] = React.useState<RequestMode>(RequestMode.OptionSelection);
  const [requestStatus, setRequestStatus] = React.useState<HouseholdRequestStatus>(new HouseholdRequestStatus());
  const [saveButtonEnabled, setSaveButtonEnabled] = React.useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  const [titleText, setTitleText] = React.useState<string>(EMPTY_STRING);
  const [transferablePasses, setTransferablePasses] = React.useState<Array<TransferablePassSignup>>([]);

  // Request options
  const [offSeason, setOffSeason] = React.useState<boolean>(false);
  const [offSeasonAvailable, setOffSeasonAvailable] = React.useState<boolean>(false);
  const [offSeasonStartDate, setOffSeasonStartDate] = React.useState<Date>(NULL_DATE);
  const [nonWeekendAvailable, setNonWeekendAvailable] = React.useState<boolean>(false);
  const [postDeadlineAvailable, setPostDeadlineAvailable] = React.useState<boolean>(false);
  const [weekendAvailable, setWeekendAvailable] = React.useState<boolean>(false);

  // Redux
  const startDate: Date = useSelector((state: any) => state.signups.offSeasonStartDate);
  const guestRequestTime: number = useSelector((state: any) => state.signups.guestRequestTime);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      width: '100%',
      margin: theme.spacing(0, 1, 0, 1),
    },
    actions: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    contentContainer: {
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2, 2, 2, 2),
      maxWidth: 500,
    },
    contentContainerMobile: {
      padding: theme.spacing(2, 2, 2, 2),
    },
    dates: {
      margin: theme.spacing(1, 0, 0, 0),
    },
    disabledDatePicker: {
      color: '#000000'
    },
    guestMsgContainer: {
      margin: theme.spacing(1, 0, 0, 0),
    },
    members: {
      margin: theme.spacing(1, 0, 0, 0),
    },
    passesContainer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(1, 0, 1, 0),
    },
    requestOptionsContainer: {
      border: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2, 2, 2, 2),
      maxWidth: 550,
    },
    requestStatus: {
      margin: theme.spacing(0, 0, 1, 0),
      padding: theme.spacing(0, 0, 1, 0),
    },
    textArea: {
      width: '100%',
    },
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: theme.spacing(0, 0, 1, 0),
    }
  };

  // #endregion

  // #region Methods

  /*
 * Clear data entry form
 */
  const clearForm = (): void => {

    // UI defaults
    setSaveButtonEnabled(false);
    setArrivalDate(EMPTY_STRING);
    setDepartureDate(EMPTY_STRING);
    setNotes(EMPTY_STRING);
    setPriorityPeriodReset(true);
    setTransferablePasses([]);

    // Set each member as selected
    setMembers(members => {
      members.forEach(m => {
        m.selected = true;
      });
      return [...members];
    });

    // Set up calendar selection restrictions
    setCalendarRestrictions();

  }

  /*
 * Check request dates overlap w/ existing request
 */
  const checkRequestDatesOverlap = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    // URL parameters
    var ids: number[] = [];
    members.forEach(member => {
      if (member.selected) {
        ids.push(member.memberID);
      }
    });

    const params = new URLSearchParams();
    params.append('arrivalDate', arrivalDate);
    params.append('departureDate', departureDate);
    params.append('ids', ids.join(','));

    const url = new URL(`${API_URL}/v1/signups/checkdates`);
    url.search = params.toString();

    return await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Unable to check dates');
        }
        return response.json()
      })
      .then((sexc: SignupResultStatus) => {
        return (sexc.statusCode === SignupExceptionEnum.NewRequestDatesOverlap);
      })
      .catch(err => {
        setErrors(err);
        return false;
      }
      );
  }

  /*
   * Fetch household member data for signup
   */
  const fetchMembers = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const id = memberID();
    const url = new URL(`${API_URL}/v1/households/${id}/members/request`);

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Household members not retrieved for signup form');
        }
        return response.json()
      })
      .then(household => {
        const mm = household.members.map(
          (m: Member) => {
            // Default selected=true
            m.selected = true;
            return m;
          });

        setMembers(mm);
      })
      .catch(err => setErrors(err));
  }

  /*
 * Get list of priority periods
 */
  const fetchPriorityPeriods = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const params = new URLSearchParams();
    // Today forward
    const today: Date = new Date();
    params.append('startDate', format(today, 'MM/d/y'));

    const url = new URL(`${API_URL}/v1/signups/priorityperiods`);
    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Priority period dates were not retrieved.');
        }
        return response.json()
      })
      .then((periods: Array<PriorityPeriod>) => {
        setPriorityPeriods(periods);
      })
      .catch(err => setErrors(err));
  }

  /*
 * Get household request summary
 */
  const fetchRequestStatus = async () => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const id = memberID();
    const url = new URL(`${API_URL}/v1/signups/householdrequeststatus/${id}`);

    return await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Unable to get household request status');
        }
        return response.json()
      })
      .then((rs: HouseholdRequestStatus) => {
        setRequestStatus(rs);
      })
      .catch(err => {
        setErrors(err);
        return false;
      }
      );
  }

  /*
   * Is the departure date before the next priority period arrival date if exists?
   */
  const isDepartureBeforeNextPriority = (): boolean => {

    let before = true;

    if (requestStatus.postDeadline && (priorityPeriods.length > 0) && (arrivalDate !== EMPTY_STRING) && (departureDate !== EMPTY_STRING)) {

      const d = new Date(departureDate);

      // Are arrival and departure before the next priority period?
      const arrivals = priorityPeriods.filter(pp => isAfter(new Date(pp.arrivalDate).getTime(), new Date(requestStatus.postDeadline.departureDate).getTime()));
      // If future priority periods exist, check them
      if (arrivals.length > 0) {
        // Add 1 day to next arrival for comparison
        const next = addDays(new Date(arrivals[0].arrivalDate), 1);
        // Is departue on or before the next priority arrival date+1 (adjusted)?
        before = isBefore(d.getTime(), next.getTime());
      }

      if (!before) {
        setpostDeadlineWarningDialogOpen(true);
      }
    }

    return before;
  }

  /*
* Validate if date selection overlaps a weekend/holiday in non-weekend mode
*/
  const overlapsWeekendHoliday = (): boolean => {
    let overlaps = false;

    if (priorityPeriods.length > 0) {
      if (arrivalDate !== EMPTY_STRING && departureDate !== EMPTY_STRING) {

        const a = new Date(arrivalDate);
        const d = new Date(departureDate);

        // Departure minus 1 to not conflict w/ priority arrival date
        // Tests if a priority period date range overlaps w/ the selected arrival/departure
        overlaps = priorityPeriods.some(pp =>
          areIntervalsOverlapping(
            { start: a, end: d },
            { start: new Date(pp.arrivalDate), end: subDays(new Date(pp.departureDate), 1) }
          )
        );

        // No priority periods matched, check overlaps post deadline
        if (!overlaps && requestStatus.postDeadline) {
          overlaps = areIntervalsOverlapping(
            { start: a, end: d },
            { start: new Date(requestStatus.postDeadline.arrivalDate), end: subDays(new Date(requestStatus.postDeadline.departureDate), 1) }
          )
        }

        if (overlaps) {
          setArrivalDate(EMPTY_STRING);
          setDepartureDate(EMPTY_STRING);
          setCalendarRestrictions();
          setNonWeekendHolidayWarningDialogOpen(true);
        }
      }
    }

    return overlaps;
  }

  /*
   * Package signup data from UI before save
   */
  const packageData = (): SignupNew => {

    // Members included in signup
    const memberSignups = new Array<MemberSignupInputForm>();
    members.forEach(member => {
      if (member.selected) {
        memberSignups.push(new MemberSignupInputForm(
          0, // memberSignupID
          0, // signupID
          member.memberID,
          new Date(arrivalDate),
          new Date(departureDate),
          false // cancel
        ));
      }
    });

    // Signup
    const id = memberID();
    const signupForm = new SignupNew(
      id, // MemberID = userID
      memberSignups,
      notes,
      transferablePasses,
      id // userID
    );

    return signupForm;
  }

  /*
 * Save signup data, first check if request dates overlap existing requests.
 */
  const save = async () => {

    setSaveButtonEnabled(false);
    if (await checkRequestDatesOverlap()) {
      enqueueSnackbar('Signup request dates overlap with an existing request for this household.', { variant: 'warning' });
      setSaveButtonEnabled(true);
      return;
    }

    // Show dialog w/ spinner
    setSaveInProgress(true);

    // Package signup data for save
    const signup = packageData();

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    await fetch(API_URL + '/v1/signups/insert', {
      method: 'POST'
      , body: JSON.stringify(signup)
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        setSaveInProgress(false);
        if (response.ok) {
          // Get request summary to see if priority request count has changed
          fetchRequestStatus();
          clearForm();
          enqueueSnackbar(`Lodge use request for ${arrivalDate} saved and acknowledgement email sent`, { variant: 'success' });
        } else {
          enqueueSnackbar('Lodge use request did not save. Please report using the Feedback form.', { variant: 'error' });
        }
      })
      .catch(error => {
        setSaveInProgress(false);
        enqueueSnackbar('Lodge use request did not save. Please report using the Feedback form.', { variant: 'error' });
      })
  }

  /*
   * Set calendar restrictions based upon mode etc
   */
  const setCalendarRestrictions = () => {

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = addDays(today, 1);
    tomorrow.setHours(0, 0, 0, 0);
    const endSkiSeason = subDays(offSeasonStartDate, 1);
    endSkiSeason.setHours(0, 0, 0, 0);

    // If offseason set dates > start
    const inOffSeason = isAfter(today, subDays(offSeasonStartDate, 1));
    if ((requestMode === RequestMode.OffSeason) || inOffSeason) {
      // Use today or off season start date whichever is later
      setMinArrival(dateFormatted(inOffSeason ? new Date() : offSeasonStartDate));
      setMinDeparture(dateFormatted(addDays(offSeasonStartDate, 1)));
    } else {
      setMinArrival(dateFormatted(today));
      setMinDeparture(dateFormatted(tomorrow));
    }
    setMaxArrival(getRequestYearEndDate());
    setMaxDeparture(getRequestYearEndDate());
  }

  /*
* Set post deadline calendar defaults
*/
  const setPostDeadlineDates = () => {
    // If post deadline dates are past, use today
    const today = new Date();
    const a = new Date(requestStatus.postDeadline.arrivalDate);
    const d = new Date(requestStatus.postDeadline.departureDate);

    setArrivalDate(dateFormatted(isPast(a) ? today : a));
    setDepartureDate(dateFormatted(isPast(d) ? addDays(today, 1) : d));
  }

  /*
 * Validate request for missing data
 */
  const validateRequest = async () => {

    if (arrivalDate === EMPTY_STRING || departureDate === EMPTY_STRING) {
      setSaveButtonEnabled(false);
      return;
    }
    // Validate if date range is not in priority period to see if overlaps priority period
    if (requestMode === RequestMode.NonWeekendHoliday && overlapsWeekendHoliday()) {
      setSaveButtonEnabled(false);
      return;
    }

    // If post deadline validate the departure date is before the next priority period
    if (requestMode === RequestMode.PostDeadline && !isDepartureBeforeNextPriority()) {
      setSaveButtonEnabled(false);
      return;
    }

    let membersSelected = 0;
    members.forEach(member => {
      if (member.selected) {
        membersSelected++;
      }
    });
    if (membersSelected === 0) {
      setSaveButtonEnabled(false);
      return;
    }

    // Enable save as all checks pass/true
    setSaveButtonEnabled(true);
  }

  // #endregion

  // #region Event handlers

  /*
  * Event handler for changes to arrival date
  */
  const handleArrivalDateChange = (date: Date | null) => {

    // Test valid date format
    if (!isDateValid(date)) {
      return;
    }

    const formattedDate = date ? dateFormatted(date) : EMPTY_STRING;

    // Clear departure if present and >= arrival
    if (formattedDate && date) {
      if (departureDate) {
        const departure: Date = new Date(departureDate);
        departure.setHours(0, 0, 0, 0);
        const arrival: Date = new Date(formattedDate);
        arrival.setHours(0, 0, 0, 0);
        if (departure <= arrival) {
          setDepartureDate(EMPTY_STRING);
          enqueueSnackbar(`Clearing departure date because new arrival is later. Re-select departure date`, { variant: 'info' });
        }
      }
    }

    setArrivalDate(formattedDate);
  };

  /*
   * Handle cancel of request input
   */
  const handleCancel = () => {
    clearForm();
    setRequestMode(RequestMode.OptionSelection);
  }

  /*
   * Handler for changes to departure date
   */
  const handleDepartureDateChange = (date: Date | null) => {
    // Test valid date format
    if (!isDateValid(date)) {
      return;
    }

    setDepartureDate(date ? dateFormatted(date) : EMPTY_STRING);

  };

  /*
* Handler for help dialog window
*/
  const handleHelpDialogClose = () => {
    setHelpDialogOpen(false);
  }

  /*
   * Hvent handler for change in check (selection) of a member
   */
  const handleMemberCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Copy array to process
    const m = [...members];
    const i = m.findIndex(m => { return m.memberID === Number(event.target.value) });
    m[i].selected = event.target.checked;

    // Update state
    setMembers([...m]);

  };

  /*
   * Handler for change in note text
   */
  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  }

  /*
   * Handle changes to transferable pass requests
   */
  const handlePassesChange = (passes: Array<TransferablePassSignup>) => {
    setTransferablePasses(passes);
  }

  /*
* Handler for non-weekend/holiday warning dialog window
*/
  const handleNonWeekendHolidayWarningDialogClose = () => {
    setNonWeekendHolidayWarningDialogOpen(false);
  }

  /*
* Handler for post deadline warning dialog window
*/
  const handlePostDeadlineWarningDialogClose = () => {
    setpostDeadlineWarningDialogOpen(false);
  }

  /*
   * Handler for changed priority period
   */
  const handlePriorityPeriodChange = (period: PriorityPeriod) => {

    // Set selected dates
    setArrivalDate(period.arrivalDate);
    setDepartureDate(period.departureDate);

    // Reset flag once a period has been selected
    setPriorityPeriodReset(false);

  }

  /*
* Handler for request mode selection
*/
  const handleRequestModeSelected = (mode: RequestMode) => {
    setRequestMode(mode);

    switch (mode) {
      case RequestMode.NonWeekendHoliday:
        setTitleText('Non-Weekend/Non-Holiday Ski Season Request');
        break;
      case RequestMode.PostDeadline:
        setTitleText(`Post-Priority Deadline Request: ${requestStatus.postDeadline.arrivalDate}`);
        setPostDeadlineDates();
        break;
      case RequestMode.OffSeason:
        setTitleText(`Offseason Request`);
        // Use off season start date if after today, otherwise today
        const arrival = isAfter(new Date(), subDays(offSeasonStartDate, 1)) ? new Date() : offSeasonStartDate;
        setMinArrival(dateFormatted(arrival));
        break;
      case RequestMode.WeekendHoliday:
        setTitleText('Weekend/Holiday Ski Season Request');
        break;
      default:
        setTitleText(EMPTY_STRING);
    }

  }

  React.useEffect(() => {
    if (startDate !== undefined || !startDate) {
      setOffSeasonStartDate(new Date(startDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {

    if (offSeasonStartDate !== undefined) {
      // Set offseason flag in local state
      // Today >= offseason start date
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      setOffSeason(today >= offSeasonStartDate);

      fetchRequestStatus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offSeasonStartDate]);

  React.useEffect(() => {
    // Request count >= 0 indicates data has been retrived from API
    if (requestStatus.requestCount >= 0) {

      if (!offSeason) {
        setNonWeekendAvailable(true);
        setOffSeasonAvailable(true);
        // Has max requests been reached?
        setWeekendAvailable((requestStatus.requestCount < requestStatus.maxRequests));

        // Are priority requests available and are there priority periods upcoming?
        if (requestStatus.futurePriorityPeriods) {
          fetchPriorityPeriods();
        }

        // If there is a post deadline
        // Today after signup deadline and before departure date
        setPostDeadlineAvailable(requestStatus.postDeadline !== null);
        setRequestMode(RequestMode.OptionSelection);
      } else {
        // Off season 
        setNonWeekendAvailable(false);
        setOffSeasonAvailable(false);
        setWeekendAvailable(false);
        setPostDeadlineAvailable(false);
        setTitleText(`Offseason Request`);
        setRequestMode(RequestMode.OffSeason);
      }

      fetchMembers();
      clearForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestStatus.requestCount]);

  React.useEffect(() => {
    if (
      (requestMode === RequestMode.WeekendHoliday)
      || (requestMode === RequestMode.PostDeadline)
      || (requestMode === RequestMode.NonWeekendHoliday)
    ) {
      validateRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestMode]);

  React.useEffect(() => {
    if (members.length > 0) {
      setFormDisabled(false);
    }
  }, [members.length]);

  React.useEffect(() => {
    if (arrivalDate) {
      setMaxDeparture(calcMaxDeparture(arrivalDate));
      setMinDeparture(calcMinDeparture(arrivalDate));
    }

    validateRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivalDate]);

  React.useEffect(() => {
    if (departureDate) {
      setMaxArrival(calcMaxArrival(departureDate));
      setMinArrival(requestMode === RequestMode.OffSeason ? dateFormatted(offSeasonStartDate) : calcMinArrival(departureDate));
    }

    validateRequest();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departureDate]);

  // #endregion

  return (
    <>
      {(requestMode === RequestMode.OptionSelection) &&
        <Box display="flex" justifyContent="center" sx={styles.root}>
          <Box sx={isMobile ? styles.contentContainerMobile : styles.contentContainer}>
            <Typography variant="h6">Ski Season Request Options</Typography>
            < RequestModeOptions
              handleModeSelection={handleRequestModeSelected}
              nonweekend={nonWeekendAvailable}
              offSeason={offSeasonAvailable}
              offSeasonStartDate={offSeasonStartDate}
              postDeadline={postDeadlineAvailable}
              requestStatus={requestStatus}
              weekend={weekendAvailable}
            />
          </Box>
        </Box>
      }
      {(requestMode !== RequestMode.OptionSelection) &&
        <Box display="flex" justifyContent="center" sx={styles.root}>
          <Box sx={isMobile ? styles.contentContainerMobile : styles.contentContainer}>
            <Grid container alignItems="center" sx={styles.title}>
              <Typography variant="h6">{titleText}</Typography>
              <Tooltip title="Lodge use request help">
                <IconButton onClick={() => setHelpDialogOpen(true)} size="large">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {(requestMode === RequestMode.WeekendHoliday) &&
              <>
                <Grid container sx={styles.requestStatus}>
                  {(requestStatus.requestCount === 0) &&
                    <Typography variant="button">{`Weekend/Holiday Requests: ${requestStatus.maxRequests} Available`}</Typography>
                  }
                  {((requestStatus.requestCount > 0 && requestStatus.requestCount < requestStatus.maxRequests)) &&
                    <Typography variant="button">{`Active Weekend/Holiday Requests: ${requestStatus.requestCount} of ${requestStatus.maxRequests} Used`}</Typography>
                  }
                </Grid>
                <Grid container justifyContent="center">
                  <PriorityPeriodList
                    disabled={formDisabled}
                    handlePriorityPeriodChange={handlePriorityPeriodChange}
                    reset={priorityPeriodReset}
                    startDate={requestStatus.postDeadline ? requestStatus.postDeadline.departureDate : EMPTY_STRING}
                  />
                </Grid>
              </>
            }
            <Grid container spacing={1} justifyContent="center" sx={styles.dates}>
              <Grid item xs={5} md={4}>
                <DatePicker
                  defaultCalendarMonth={minArrival ? new Date(minArrival) : undefined}
                  disabled={formDisabled}
                  disablePast
                  label="Arrival"
                  minDate={minArrival ? new Date(minArrival) : undefined}
                  maxDate={maxArrival ? new Date(maxArrival) : undefined}
                  onChange={handleArrivalDateChange}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} />}
                  value={arrivalDate === EMPTY_STRING ? null : new Date(arrivalDate)}
                  views={['year', 'month', 'day']}
                />
              </Grid>
              <Grid item xs={5} md={4}>
                <DatePicker
                  defaultCalendarMonth={arrivalDate ? new Date(arrivalDate) : undefined}
                  disabled={formDisabled}
                  disablePast
                  inputFormat="MM/dd/yyyy"
                  label="Departure"
                  minDate={minDeparture ? new Date(minDeparture) : undefined}
                  maxDate={maxDeparture ? new Date(maxDeparture) : undefined}
                  onChange={handleDepartureDateChange}
                  renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField required {...params} inputProps={{ ...params.inputProps, readOnly: true, placeholder: '' }} />}
                  value={departureDate === EMPTY_STRING ? null : new Date(departureDate)}
                  views={['year', 'month', 'day']}
                />
              </Grid>
            </Grid>
            <Grid container sx={styles.members}>
              {members.map(m => (
                <Grid container spacing={0} key={m.memberID}>
                  <FormControl disabled={formDisabled}>
                    <FormControlLabel
                      control={<Checkbox key={m.memberID} onChange={handleMemberCheckChange} value={m.memberID} checked={m.selected} />}
                      label={`${m.firstName}  ${m.lastName} (${m.memberStatus.status})`}
                    />
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            {(requestMode !== RequestMode.OffSeason) &&
              <Grid container sx={styles.passesContainer}>
                <Typography variant="subtitle2" mt={2} gutterBottom>Transferable Pass Requests</Typography>
                <TransferablePasses
                  handlePassesChange={handlePassesChange}
                  minArrivalDate={arrivalDate}
                  maxDepartureDate={departureDate}
                  passes={transferablePasses}
                />
              </Grid>
            }
            <Grid container>
              <Typography variant="subtitle2" gutterBottom>Notes</Typography>
              <TextareaAutosize
                style={styles.textArea}
                disabled={formDisabled}
                maxRows={10}
                minRows={5}
                onChange={handleNotesChange}
                placeholder="Enter additional lodge use request info such as Bedroom Sharing, No Dinner, Need Ride, etc."
                value={notes}
              />
            </Grid>
            <Grid container sx={styles.guestMsgContainer}>
              <Typography variant="subtitle2">IMPORTANT: Guests must be added in My Lodge Use w/in {guestRequestTime} min after saving to have the same request time.</Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center" sx={styles.actions}>
              <Grid item>
                <Tooltip title="Cancel request entry and reset form" arrow>
                  <span>
                    <Button variant="outlined" size={isMobile ? 'small' : 'medium'} color="primary" disabled={formDisabled} onClick={handleCancel}>Cancel</Button>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="Save lodge use request" arrow>
                  <span>
                    <Button variant="contained" disabled={!saveButtonEnabled || formDisabled ? true : false} size={isMobile ? 'small' : 'medium'} color="primary" onClick={save}>Save</Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        </Box>
      }
      <NonWeekendHolidayWarningDialog
        handleClose={handleNonWeekendHolidayWarningDialogClose}
        show={nonWeekendHolidayWarningDialogOpen}
      />
      <PostDeadlineWarningDialog
        handleClose={handlePostDeadlineWarningDialogClose}
        requestStatus={requestStatus}
        show={postDeadlineyWarningDialogOpen}
      />
      <RequestFormHelpDialog
        handleClose={handleHelpDialogClose}
        maxRequests={requestStatus.maxRequests}
        show={helpDialogOpen}
      />
      <Dialog
        open={saveInProgress}
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitle>
          <Typography variant="inherit">Saving {arrivalDate} Lodge Use Request</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={0}
            justifyContent="center"
          >
            <CircularProgress />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );

}

export default RequestForm;
