import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import Bedrooms from './Bedrooms';
import Cancellations from './Cancellations';
import EmailForm from './EmailForm';
import Guests from './Guests';
import LodgeUseFilter from './Filter';
import Summary from './Summary';
import PostDeadline from './PostDeadline';
import Requests from './Requests';

import {
  HTTPHeaders,
  LodgeUseMemberRequest
} from "../../interfaces";

import {
  DateRange,
  LodgeUseData,
  LodgeUseRequest,
  LodgeUseStatusChange,
  LodgeUseSummary
} from '../../models';

import {
  API_URL,
  EMPTY_STRING,
  LodgeUseView
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// Date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

const LodgeUse: React.FC = () => {

  const { getAccessToken } = useAuth0User();
  const { isMobile } = useResponsive();
  const { timeStamp } = useDateFormatting();
  const { enqueueSnackbar } = useSnackbar();

  const [bedroomsClear, setBedroomsClear] = React.useState<boolean>(false);
  const [bedroomsRefresh, setBedroomsRefresh] = React.useState<boolean>(false);
  const [cancellationsClear, setCancellationsClear] = React.useState<boolean>(false);
  const [clearRequests, setClearRequests] = React.useState<boolean>(false);
  const [dateRange, setDateRange] = React.useState<DateRange>(new DateRange());
  const [disableClear, setDisableClear] = React.useState<boolean>(true);
  const [disableFilter, setDisableFilter] = React.useState<boolean>(false);
  const [disableRefresh, setDisableRefresh] = React.useState<boolean>(true);
  const [emailIDs, setEmailIDs] = React.useState<Array<number>>([]);
  const [emailDrawerVisible, setEmailDrawerVisible] = React.useState<boolean>(false);
  const [guests, setGuests] = React.useState<Array<LodgeUseMemberRequest>>([]);
  const [errors, setErrors] = React.useState(false);
  const [households, setHouseholds] = React.useState<Array<LodgeUseRequest>>([]);
  const [postDeadline, setPostDeadline] = React.useState<Array<LodgeUseMemberRequest>>([]);
  const [refreshCancellations, setRefreshCancellations] = React.useState<boolean>(true);
  const [refreshEmailQueue, setRefreshEmailQueue] = React.useState<boolean>(false);
  const [statusTime, setStatusTime] = React.useState<string>(EMPTY_STRING);
  const [summary, setSummary] = React.useState<LodgeUseSummary>(new LodgeUseSummary());
  const [view, setView] = React.useState<LodgeUseView>(LodgeUseView.HouseholdRequests);
  const [working, setWorking] = React.useState<boolean>(false);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      height: '100%',
    },
    contentContainer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      flexGrow: 1,
      height: 500
    },
    emailDrawer: {
      flex: 1,
      padding: theme.spacing(2)
    },
    filterContainer: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    toolsContainer: {
      flexGrow: 1,
      padding: theme.spacing(1, 0, 1, 0)
    },
  };

  // #endregion

  // #region Methods

  /*
   * Action to send email to selected members
   */
  const actionEmail = (ids: Array<number>) => {
    setEmailIDs(ids);
    // Send email to all selected members
    setEmailDrawerVisible(true);
  }

  const actionBedroom = () => {

    // Assign selected members to a bedroom

  }

  /*
 * Fetch lodge use based upon filter
 */
  const fetchLodgeUse = async () => {

    setWorking(true);

    // Used to ensure clean load
    // May be bug in MUI extra are requests are retained
    setHouseholds([]);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    // Always show current date forward
    const params = new URLSearchParams();
    if (dateRange !== undefined) {
      if (dateRange.startDate) {
        params.append('StartDate', dateRange.startDate);
      }
      if (dateRange.endDate) {
        params.append('EndDate', dateRange.endDate);
      }
    }

    if (!params.toString()) {
      setWorking(false);
      return;
    }

    const url = new URL(`${API_URL}/v1/lodgeuse`);
    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          setWorking(false);
          throw new Error('Lodge use list not retrieved');
        }
        return response.json()
      })
      .then((lu: LodgeUseData) => {
        // Update email queue
        setRefreshEmailQueue(refreshEmailQueue => !refreshEmailQueue);
        setSummary(lu.summary);
        setStatusTime(timeStamp());
        // Set state of view actions
        toolActionsDisable(lu.households);
        if (view === LodgeUseView.GuestRequests || view === LodgeUseView.PostDeadlineRequests) {
          filterHouseholds(lu.households);
        }

        setHouseholds(lu.households);
        setWorking(false);
      })
      .catch(err => {
        setWorking(false);
        setErrors(err);
      });
  }

  /*
   * Filter view locally to subset of household requests
   */
  const filterHouseholds = (h: Array<LodgeUseRequest>) => {

    //let filtered = [];

    switch (view) {
      case LodgeUseView.GuestRequests:
        // Filter to households requests w/ guests (offseason or priority request)
        const g = h.flatMap(h => h.memberRequests.filter(r => r.isGuest && (r.offSeason || r.priorityRequest)));
        const gs = g.sort((a, b) => {
          if (a.rank > b.rank) {
            return 1;
          } else {
            return -1;
          }
        });
        setGuests(gs);
        break;
      case LodgeUseView.PostDeadlineRequests:
        // Filter to households requests post deadline (not offseason  and not priority request) sorted by request date
        const pd = h.flatMap(h => h.memberRequests.filter(r => (!r.offSeason && !r.priorityRequest)));
        const pds = pd.sort((a, b) => {
          if (new Date(a.requestDate) > new Date(b.requestDate)) {
            return 1;
          } else {
            return -1;
          }
        });
        setPostDeadline(pds);
        break;
    }

  }

  /*
   * Set state of filter and view actions based upon type of view
   */
  const toolActionsDisable = (households: Array<LodgeUseRequest>) => {
    switch (view) {
      case LodgeUseView.HouseholdRequests:
        setDisableClear(households.length === 0);
        setDisableFilter(false);
        setDisableRefresh(households.length === 0);
        break;
      case LodgeUseView.GuestRequests:
        setDisableClear(households.length === 0);
        setDisableFilter(false);
        setDisableRefresh(households.length === 0);
        break;
      case LodgeUseView.PostDeadlineRequests:
        setDisableClear(households.length === 0);
        setDisableFilter(false);
        setDisableRefresh(households.length === 0);
        break;
      case LodgeUseView.Cancelled:
        setDisableClear(false);
        setDisableFilter(false);
        setDisableRefresh(false);
        break;
      //case LodgeUseView.EmailQueue:
      //  setDisableClear(true);
      //  setDisableFilter(true);
      //  setDisableRefresh(false);
      //  break;
      default:
    }
  }

  /*
   * Update request status
   */
  const updateStatus = async (statusChange: LodgeUseStatusChange) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/lodgeuse/status/update`);

    await fetch(url.toString(), {
      body: JSON.stringify(statusChange),
      headers: headers,
      method: 'POST'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Lodge use action status error');
        }
        fetchLodgeUse();
        enqueueSnackbar('Lodge use status updated for selected individuals', { variant: 'success' });
      })
      .catch(err => {
        setErrors(err);
      });

  }

  /*
  * Update modified flag in a household 
  * Causes render
  */
  const updateModifiedFlag = (id: number) => {

    const hs = [...households];
    const h = hs.find(h => h.signupID === id);
    if (h) {
      h.modified = false;
      setHouseholds(hs);
    }

  }

  // #endregion

  // #region Event handlers

  /*
  * Handle clear of lodge use components
  */
  const handleClear = () => {

    if (
      view === LodgeUseView.HouseholdRequests
      || view === LodgeUseView.GuestRequests
      || view === LodgeUseView.PostDeadlineRequests
      || view === LodgeUseView.Cancelled
    ) {
      setHouseholds([]);
      setView(LodgeUseView.HouseholdRequests);
      setSummary(new LodgeUseSummary());
      setDateRange(new DateRange());
      setStatusTime(EMPTY_STRING);
      setCancellationsClear(clear => !clear)
      setClearRequests(clear => !clear);
    } else if (
      view === LodgeUseView.Bedrooms
    ) {
      setBedroomsClear(clear => !clear);
    }


  }

  /*
* Handle closing of email drawer
*/
  const handleEmailDrawerClose = () => {
    setEmailDrawerVisible(false);
  };

  /*
   * Handle changes to filter
   */
  const handleFilterChanged = (range: DateRange) => {
    setDateRange(range);
  }

  /*
   * Handler refresh of grid
   */
  const handleRefresh = () => {
    switch (view) {
      case LodgeUseView.HouseholdRequests:
        fetchLodgeUse();
        break;
      case LodgeUseView.GuestRequests:
        fetchLodgeUse();
        break;
      case LodgeUseView.PostDeadlineRequests:
        fetchLodgeUse();
        break;
      case LodgeUseView.Cancelled:
        setRefreshCancellations(refreshCancellations => !refreshCancellations);
        break;
      default:
    }
  }

  /*
   * Reset modified flag for request that has been viewed
   */
  const handleModifiedViewed = async (id: number) => {

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    const url = new URL(`${API_URL}/v1/signups/modified/${id}`);

    await fetch(url.toString(), {
      method: 'DELETE'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Request modified flag not reset');
        }
        updateModifiedFlag(id);
      })
      .catch(err => {
        setErrors(err);
      });

  }

  /*
  * Set lodge use view of data (All, cancellations, etc.)
  */
  const handleViewChanged = (v: LodgeUseView) => {

    if (view !== v) {
      setView(v);
    }

  }

  React.useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      fetchLodgeUse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.startDate, dateRange.endDate]);

  React.useEffect(() => {

    if (view === LodgeUseView.GuestRequests || view === LodgeUseView.PostDeadlineRequests) {
      filterHouseholds(households);
    }

    // Set view actions disabled state
    toolActionsDisable(households);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  React.useEffect(() => {
    // Default to viewing all requests
    setView(LodgeUseView.HouseholdRequests);
  }, []);

  // #endregion

  return (
    <>
      <Box sx={styles.root}>
        <Grid container spacing={0} sx={styles.toolsContainer}>
          <Grid item xs={6} sx={styles.filterContainer}>
            <LodgeUseFilter
              clear={clearRequests}
              disableClear={disableClear}
              disableFilter={disableFilter}
              disableRefresh={disableRefresh}
              dateRange={dateRange}
              filterChanged={handleFilterChanged}
              handleClear={handleClear}
              handleRefresh={handleRefresh}
              handleView={handleViewChanged}
            />
          </Grid>
          <Grid item xs={6}>
            <Summary
              statusTime={statusTime}
              summary={summary}
            />
          </Grid>
        </Grid>
        <Grid container sx={styles.contentContainer}>
          {view === LodgeUseView.HouseholdRequests &&
            <Requests
              refreshEmailQueue={refreshEmailQueue}
              handleEmail={actionEmail}
              handleStatusChange={updateStatus}
              lodgeUseRequests={households}
              modifiedViewed={handleModifiedViewed}
              working={working}
            />
          }
          {view === LodgeUseView.GuestRequests &&
            <Guests
              refreshEmailQueue={refreshEmailQueue}
              handleStatusChange={updateStatus}
              requests={guests}
            />
          }
          {view === LodgeUseView.PostDeadlineRequests &&
            <PostDeadline
              refreshEmailQueue={refreshEmailQueue}
              handleEmail={actionEmail}
              handleStatusChange={updateStatus}
              requests={postDeadline}
            />
          }
          {view === LodgeUseView.Cancelled &&
            <Cancellations
              clear={cancellationsClear}
              dateRange={dateRange}
              refresh={refreshCancellations}
            />
          }
          {view === LodgeUseView.Bedrooms &&
            <Bedrooms
              clear={bedroomsClear}
              dateRange={dateRange}
              refresh={bedroomsRefresh}
            />
          }
        </Grid>
      </Box>
      <Drawer
        variant="temporary"
        anchor={isMobile ? 'bottom' : 'right'}
        open={emailDrawerVisible}
        onClose={handleEmailDrawerClose}
      >
        <Box sx={styles.emailDrawer}>
          <EmailForm
            ids={emailIDs}
          />
        </Box>
      </Drawer>
    </>
  );

}

export default LodgeUse;