// #region Imports

import React from 'react';
import { format } from 'date-fns';

// Material-UI
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Chip from '@mui/material/Chip';
import { amber, blue, blueGrey, green, red } from '@mui/material/colors';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridOverlay
} from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';
import Tooltip from '@mui/material/Tooltip';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  RequestCalendarColumnHeader,
  RequestCalendarRow
} from '../../models';

import {
  EMPTY_STRING,
  SignupCalendarView,
  SignupStatusID
} from '../../global';

// #endregion

// #region Interfaces

interface CaldendarGridProps {
  columnHeaders: Array<RequestCalendarColumnHeader>;
  isLoading: boolean;
  rows: Array<RequestCalendarRow>;
}

// #endregion

const CalendarGrid: React.FC<CaldendarGridProps> = (props) => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)'
      }
    },
    confirmed: {
      color: green[500]
    },
    deined: {
      color: red[500]
    },
    cancelled: {
      color: blue[500]
    },
    cancelledlate: {
      color: red[500]
    },
    nightCount: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
      margin: theme.spacing(0, 0, 0, 1)
    },
    noRows: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(2, 0, 2, 0)
    },
    pending: {
      color: blueGrey[500]
    },
    waitlist: {
      color: amber[500]
    }
  };

  // #endregion

  // #region Methods

  /*
 * Grid column definitions
 */
  const columns = (): Array<GridColDef> => {
    const base: Array<GridColDef> = [
      {
        field: 'referenceNumber',
        headerName: '#',
        width: 85,
        type: 'string',
      }, {
        field: 'fullName',
        headerName: 'Member/Guest',
        width: 175,
        type: 'string',
        renderCell: (params: GridCellParams) => (
          renderName(params)
        )
      }, {
        field: 'rank',
        headerName: 'Rank',
        width: 15,
        type: 'string',
      }, {
        field: 'personType',
        headerName: 'Type',
        width: 150,
        type: 'string',
      }];

    const nights: Array<GridColDef> = props.columnHeaders.map((ch, index) => {
      return {
        field: `night${(index + 1)}`,
        headerAlign: 'center',
        renderHeader: (params: GridColumnHeaderParams) => (
          renderColumnHeader(params, index)
        ),
        align: 'center',
        width: 145,
        type: 'string',
        renderCell: (params: GridCellParams) => (
          renderStatus(params)
        )
      };
    });

    return [...base, ...nights];

  }

  /*
   * Overlay displayed when no data
   */
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No requests for the selected date range and view</div>
      </GridOverlay>
    );
  }

  /*
   * Render the header for a date column
   */
  const renderColumnHeader = (params: GridColumnHeaderParams, index: number) => {

    const date = format(new Date(props.columnHeaders[index].lodgeNight), 'EEEEEE M/d');
    return (
      <div><span>{date}</span>{renderNightCount(props.columnHeaders[index].nightCount)}</div>
    );

  }

  /*
   * Render name for row
   */
  const renderName = (params: GridCellParams) => {
    const isG = params.row.isGuest as boolean;

    if (isG) {
      return (
        <Tooltip title={`Guest of ${params.row.guestOf || ''}`} arrow>
          <span>{params.row.lastName || ''}, {params.row.firstName || ''}{params.row.priorityGuest ? '*' : EMPTY_STRING}</span>
        </Tooltip>
      );
    } else {
      return (
        <span>{params.row.lastName || ''}, {params.row.firstName || ''}</span>
      );
    }

  }

  /*
 * Render night count and color based upon rank
 */
  const renderNightCount = (nightCount: number) => {

    if (nightCount > 0) {

      return (
        <Chip
          variant="outlined"
          size="small"
          sx={styles.nightCount}
          label={nightCount} />
      )
    } else {
      return (<span />)
    }

  }

  /*
   * Render the cell for signup status
   */
  const renderStatus = (params: GridCellParams) => {

    const id = params.value as number;

    switch (id) {
      case SignupStatusID.Pending:
        return (
          <InfoIcon fontSize="medium" sx={styles.pending} />
        )
      case SignupStatusID.Confirmed:
        return (
          <CheckCircleIcon fontSize="medium" sx={styles.confirmed} />
        )
      case SignupStatusID.Waitlist:
        return (
          <PanToolIcon fontSize="medium" sx={styles.waitlist} />
        )
      case SignupStatusID.Denied:
        return (
          <BlockIcon fontSize="medium" sx={styles.deined} />
        )
      case SignupStatusID.Cancelled:
        return (
          <CancelIcon fontSize="medium" sx={styles.cancelled} />
        )
      case SignupStatusID.CancelledLate:
        return (
          <CancelIcon fontSize="medium" sx={styles.cancelledlate} />
        )
      default:
        return (<span />)
    }

  }

  // #endregion

  return (
    <DataGrid
      columns={columns()}
      rows={props.rows}
      getRowId={(row) => row.rowID} // Special unique ID for each row
      sx={styles.root}
      density="compact"
      disableColumnSelector={true}
      disableSelectionOnClick={true}
      hideFooter={true}
      loading={props.isLoading}
      components={{
        NoRowsOverlay: noRowsOverlay
      }}
    />
  );

}

export default CalendarGrid;