// #region Imports

import React from 'react';

// Material-UI
import BlockIcon from '@mui/icons-material/Block';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { amber, blue, blueGrey, green, red } from '@mui/material/colors';
import {
  DataGrid,
  GridCellParams,
  GridCellValue,
  GridColDef,
  GridOverlay,
  GridSortCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import PanToolIcon from '@mui/icons-material/PanTool';

// Styles
import { useTheme } from '@mui/material/styles';

import {
  RequestCalendarRow
} from '../../models';

import {
  EMPTY_STRING,
  SignupStatusID
} from '../../global';

// #endregion

// #region Interfaces

interface CalendarGridMobileProps {
  isLoading: boolean;
  rows: Array<RequestCalendarRow>;
}

// #endregion

const CalendarGridMobile: React.FC<CalendarGridMobileProps> = (props) => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      margin: theme.spacing(0, 0, 0, 0)
    },
    grid: {
      '& .super-app-theme--header': {
        backgroundColor: 'rgba(255, 7, 0, 0.55)'
      }
    },
    nightCount: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
      margin: theme.spacing(0, 0, 0, 1)
    },
    noRows: {
      margin:theme.spacing(2,0,0,0),
      padding: theme.spacing(2, 0, 2, 0)
    },
    confirmed: {
      color: green[500]
    },
    pending: {
      color: blueGrey[500]
    },
    waitlist: {
      color: amber[500]
    },
    deined: {
      color: red[500]
    },
    cancelled: {
      color: blue[500]
    },
    cancelledlate: {
      color: red[500]
    }
  };

  // #endregion

  // #region Methods

  /*
 * Grid column definitions
 */
  const columns = (): Array<GridColDef> => {

    return [
      {
        field: 'fullName',
        headerName: 'Member/Guest',
        width: 160,
        type: 'string',
        renderCell: (params: GridCellParams) => (
          renderName(params)
        )
      }, {
        field: 'rank',
        headerName: 'Rank',
        width: 15,
        type: 'string',
      }, {
        field: 'arrivalDate',
        headerName: 'Dates',
        width: 100,
        type: 'string',
        valueGetter: renderDates
      }, {
        field: 'signupStatusID',
        headerName: '',
        align: 'center',
        width: 50,
        type: 'number',
        renderCell: (params: GridCellParams) => (
          renderStatus(params)
        )
      }
    ];

  }

  /*
   * Overlay displayed when no data
   */
  const noRowsOverlay = () => {
    return (
      <GridOverlay>
        <div style={styles.noRows}>No requests for the selected date range</div>
      </GridOverlay>
    );
  }

  /*
   * Render grid cell date
   */
  const renderDates = (params: GridValueGetterParams) => {
    const a = new Date(params.row.arrivalDate);
    const d = new Date(params.row.departureDate);

    return `${a.getMonth() + 1}/${a.getDate()}-${d.getMonth() + 1}/${d.getDate()}`;
  }

  /*
   * Render name for row
   */
  const renderName = (params: GridCellParams) => {
    const isG = params.row.isGuest as boolean;
    return <span>{params.row.lastName || ''}, {params.row.firstName || ''}{isG ? ' (G)' : EMPTY_STRING}</span>
  }

  /*
 * Render the cell for signup status
 */
  const renderStatus = (params: GridCellParams) => {

    const id = params.value as number;

    switch (id) {
      case SignupStatusID.Pending:
        return (
          <InfoIcon fontSize="medium" sx={styles.pending} />
        )
      case SignupStatusID.Confirmed:
        return (
          <CheckCircleIcon fontSize="medium" sx={styles.confirmed} />
        )
      case SignupStatusID.Waitlist:
        return (
          <PanToolIcon fontSize="medium" sx={styles.waitlist} />
        )
      case SignupStatusID.Denied:
        return (
          <BlockIcon fontSize="medium" sx={styles.deined} />
        )
      case SignupStatusID.Cancelled:
        return (
          <CancelIcon fontSize="medium" sx={styles.cancelled} />
        )
      case SignupStatusID.CancelledLate:
        return (
          <CancelIcon fontSize="medium" sx={styles.cancelledlate} />
        )
      default:
        return (<span />)
    }

  }

  // #endregion

  return (
    <Box sx={styles.root}>
      <DataGrid
        columns={columns()}
        rows={props.rows}
        getRowId={(row) => row.rowID} // Special unique ID for each row
        sx={styles.grid}
        autoHeight={true}
        density="compact"
        disableColumnSelector={true}
        disableSelectionOnClick={true}
        hideFooter={true}
        loading={props.isLoading}
        components={{
          NoRowsOverlay: noRowsOverlay
        }}
      />
    </Box>
  )

}

export default CalendarGridMobile;