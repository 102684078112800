import React from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Styles
import { useTheme } from '@mui/material/styles';

// Components
import CalendarFilter from './CalendarFilter';
import CalendarGrid from './CalendarGrid'
import CalendarGridMobile from './CalendarGridMobile'
import CalendarSummary from './CalendarSummary';

import {
  HTTPHeaders
} from "../../interfaces";

import {
  DateRange,
  RequestCalendar,
  RequestCalendarColumnHeader,
  RequestCalendarRow,
  RequestCalendarSummary,
} from '../../models';

import {
  API_URL,
  EMPTY_STRING,
  SignupCalendarView
} from '../../global';

// Auth0 user hooks 
import { useAuth0User } from '../../hooks/UseAuth0User';

// Responsive hooks
import { useResponsive } from '../../hooks/UseResponsive';

// Date formatting
import { useDateFormatting } from '../../hooks/UseDateFormatting';

const Calendar: React.FC = () => {

  const { getAccessToken, memberID } = useAuth0User();
  const { isMobile } = useResponsive();
  const { timeStamp } = useDateFormatting();

  const [columnHeaders, setColumnHeaders] = React.useState<Array<RequestCalendarColumnHeader>>([]);
  const [rows, setRows] = React.useState<Array<RequestCalendarRow>>([]);
  const [summary, setSummary] = React.useState<RequestCalendarSummary>(new RequestCalendarSummary());

  const [calendarView, setCalendarView] = React.useState<SignupCalendarView>(SignupCalendarView.All);
  const [clear, setClear] = React.useState<boolean>(false);
  const [dateRange, setDateRange] = React.useState<DateRange>(new DateRange());
  const [disableActions, setDisableActions] = React.useState<boolean>(true);
  const [errors, setErrors] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [statusTime, setStatusTime] = React.useState<string>(EMPTY_STRING);

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
    },
    actionContainer: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    filterContainer: {
      [theme.breakpoints.up("sm")]: {
        borderRight: `1px solid ${theme.palette.divider}`,
      },
    },
    gridContainer: {
      flexGrow: 1,
      overflow: 'auto',
      margin: theme.spacing(0, 0, 0, 0),
      [theme.breakpoints.down("sm")]: {
        height: 500,
      },
      [theme.breakpoints.up("md")]: {
        height: 500,
      },
    },
    toolsContainer: {
      flexGrow: 1,
      padding: theme.spacing(1, 0, 1, 0),
    },
  }

  // #endregion

  // #region Methods

  /*
* Clear calendar, filter, and summary
*/
  const clearCalendar = () => {
    setColumnHeaders([]);
    setRows([]);
    setSummary(new RequestCalendarSummary());
    setStatusTime(EMPTY_STRING);
  }

  /*
 * Fetch signup calendar based upon filter
 */
  const fetchCalendar = async () => {

    setIsLoading(true);

    // Auth0 API token
    const accessToken = await getAccessToken();

    const headers: HTTPHeaders = {};
    headers['Content-Type'] = 'application/json';
    headers['Accept'] = 'application/json';
    headers['Authorization'] = `Bearer ${accessToken}`;

    // Always show current date forward
    const params = new URLSearchParams();
    const id = memberID();
    if (dateRange !== undefined) {
      params.append('memberID', id.toString());
      if (dateRange.startDate) {
        params.append('startDate', dateRange.startDate);
      }
      if (dateRange.endDate) {
        params.append('endDate', dateRange.endDate);
      }
    }

    const url = new URL(`${API_URL}/v1/signups/calendar`);
    url.search = params.toString();

    await fetch(url.toString(), {
      method: 'GET'
      , cache: 'no-cache'
      , headers: headers
      , mode: 'cors'
    })
      .then((response) => {
        if (!response.ok) {
          setIsLoading(false);
          throw new Error('Signup calendar not retrieved');
        }
        return response.json()
      })
      .then((calendar: RequestCalendar) => {
        setColumnHeaders(calendar.columnHeaders);
        setRows(calendar.rows);
        setSummary(calendar.summary);
        setStatusTime(timeStamp());
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setErrors(err);
      });
  }

  /*
 * Requests based upon view
 */
  const filterView = (): Array<RequestCalendarRow> => {

    switch (calendarView) {
      case SignupCalendarView.Household:
        return rows.filter(r => r.householdID === summary.householdID);
      case SignupCalendarView.All:
        return rows;
      default:
        return rows;
    }

  }

  // #endregion

  // #region Event handlers

  /*
   * Handle clear of calendar components
   */
  const handleClear = () => {
    clearCalendar();
    setDateRange(new DateRange());

    setClear(prevClear => !prevClear);
    setDisableActions(true);
  }

  /*
   * Handle changes to filter
   */
  const handleFilterChanged = (range: DateRange) => {
    setDateRange(range);
  }

  /*
   * Handle refresh of calendar
   */
  const handleRefresh = () => {
    fetchCalendar();
  }

  /*
 * Set calendar view of data (All, cancellations, etc.)
 */
  const handleViewChanged = (view: SignupCalendarView) => {
    setCalendarView(view);
    if (dateRange.startDate && dateRange.endDate) {
      fetchCalendar();
    }
  }

  React.useEffect(() => {
    if (dateRange.startDate && dateRange.endDate) {
      fetchCalendar();
      setDisableActions(false);
    } else {
      setDisableActions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange.startDate, dateRange.endDate]);

  React.useEffect(() => {
    // Default to viewing all requests
    setCalendarView(SignupCalendarView.All);
  }, []);

  // #endregion

  return (
    <Box sx={styles.root}>
      <Grid container item spacing={0} sx={styles.toolsContainer}>
        <Grid item xs={12} md={6} sx={styles.filterContainer}>
          <CalendarFilter
            clearFilter={clear}
            dateRange={dateRange}
            handleFilterChanged={handleFilterChanged}
            disableActions={disableActions}
            handleClear={handleClear}
            handleRefresh={handleRefresh}
            handleViewChanged={handleViewChanged}
          />
        </Grid>
        {!isMobile &&
          <Grid item xs={6}>
            <CalendarSummary
              statusTime={statusTime}
              summary={summary}
            />
          </Grid>
        }
      </Grid>
      <Grid container item sx={styles.gridContainer}>
        {!isMobile &&
          <CalendarGrid
            columnHeaders={columnHeaders}
            isLoading={isLoading}
            rows={filterView()}
          />
        }
        {isMobile &&
          <CalendarGridMobile
            isLoading={isLoading}
            rows={rows}
          />
        }
      </Grid>
    </Box>
  );

}

export default Calendar;