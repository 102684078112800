import React from 'react';

// #region imports

// Material-UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Styles
import { useTheme } from '@mui/material/styles';

// #endregion

const MyLodgeUseRequestEditHelp: React.FC = () => {

  // #region Styles

  const theme = useTheme();
  const styles = {
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  };

  // #endregion

  return (
    <Box sx={styles.root}>
      <Typography variant="h6" paragraph>Add a Guest</Typography>
      <Typography variant="body1" paragraph>A guest can be added by clicking the Add Guest button and completing the guest information. Please enter the complete name of each guest.</Typography>
      <Typography variant="h6" paragraph>Revise Notes</Typography>
      <Typography variant="body1" paragraph>Some uses for Notes are indicating a work weekend, specifying no dinner, ask for a ride, offer a ride, or any other information. Notes are visible to all members.</Typography>
      <Typography variant="h6" paragraph>Transferable Passes</Typography>
      <Typography variant="body1" paragraph>A maximum of two(2) transferable passes can be requested. Lodge managers have priority use of passes during priority periods (weekends/holidays) and all other times they are on a first come, first served basis.</Typography>
      <Typography variant="h6" paragraph>Change Arrival or Departure Dates</Typography>
      <Typography variant="body1" paragraph>The arrival or departure date can be changed for one or more members or guests by clicking in the date field and selecting the revised date in the calendar. The maximum stay is 14 days so only dates w/in that range will be selectable. Departure date must be at least one day after the arrival date.</Typography>
      <Typography variant="body1" paragraph>When making a request, please adhere to the policy that anyone that is not an Active and Life members can not stay at the lodge alone. The Signup Coordinator will review requests for such invalid dates.</Typography>
      <Typography variant="h6" paragraph>Add a Household Member Not in the Request</Typography>
      <Typography variant="body1" paragraph>If the orignal lodge use request did not include one or more household members they will be listed. These household members can be added to the request. By default, their arrival and departure dates will be set to the minimum and maximum dates already in the request, respectively.</Typography>
      <Typography variant="h6" paragraph>Cancel an Individual Member or Guest</Typography>
      <Typography variant="body1" paragraph>An individual member or guest already in the request can be "cancelled", i.e., removed, by clicking the "X" icon. A confirmation dialog will be displayed before the individual is removed from the request. One a member or guest is removed, they lose their rank position. If the member or guest is later added to the request their rank is based upon the date/time they are added back.</Typography>
      <Typography variant="h6" paragraph>Cancel the Entire Request</Typography>
      <Typography variant="body1" paragraph>If everyone in the request no longer intends to use the lodge on those dates, the entire request can be cancelled by clicking Cancel Request. A confirmation dialog will be displayed before the request is cancelled. Note, that a new request for the same dates will have a rank based upon the date/time of the new request.</Typography>
    </Box >
  )

  // #endregion

}

export default MyLodgeUseRequestEditHelp;