export const AUTH0_CONFIG = {

  domain: 'montclairskiclub.auth0.com',

  //// ***Local
  //clientID: 'aXZtTX9fyx3S03EStJa5KO27v0N072UH',
  //redirectUri: 'https://localhost:44325',
  //audience: 'https://api.localmontclairskiclub.com'

  // ***azure beta
  //clientID: 'OvSxg0eAFrZDU2tvodffDJfcy4uVncXr',
  //redirectUri: 'https://montclairskiclub-beta.azurewebsites.net',
  //audience: 'https://api.betamontclairskiclub.com'
 
  // ***Azure Production
  clientID: '9hl8ySuGc027y5RXdTpo6gq2lQiYnkyE',
  redirectUri: 'https://www.montclairskiclub.com',
  audience: 'https://api.montclairskiclub.com'

};
